























import { defineComponent } from '@vue/composition-api';
import HeatingSystemListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import CustomersQuery from '@/features/app-customer/views/customer/view.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  HeatingSystemCollectionWithPaginationQuery,
  HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items,
} from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { HeatingSystemMetadataResponse } from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import { AppCustomerViewQuery } from '@/features/app-customer/views/customer/__generated__/AppCustomerViewQuery';

export default defineComponent({
  data() {
    return {
      heatingSystems: null as HeatingSystemCollectionWithPaginationQuery | null,
      heatingSystemsMetadata: [] as HeatingSystemMetadataResponse[] | null,
      incompleteMetatadata: [] as HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items[],
      customerIds: [] as string[],
    };
  },
  computed: {
    userId(): string {
      return this.$store.getters.userId;
    },
  },
  mounted() {
    Promise.all([this.fetchCustomers(), this.fetchHeatingSystemsMetadata(), this.fetchHeatingSystems()]).then(() => {
      this.heatingSystems?.heatingSystemsList.items.forEach((hs) => {
        const incomplete = this.heatingSystemsMetadata?.find(
          (metadata) => metadata.heatingsystemId !== hs.id || !this.isComponentMetadataCompleted(metadata),
        );
        if (incomplete && this.customerIds.includes(hs.customerId)) {
          this.incompleteMetatadata.push(hs);
        }
      });
    });
  },
  methods: {
    async fetchHeatingSystemsMetadata(): Promise<void> {
      try {
        const res = await this.$store.dispatch('getAllHeatingSystemMetadata');
        const data = await res.json();
        this.heatingSystemsMetadata = data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async fetchCustomers(): Promise<void> {
      try {
        const { data } = await this.$apollo.query<AppCustomerViewQuery>({
          query: CustomersQuery,
          client: APOLLO_CLIENT.PORTAL_CLIENT,
          fetchPolicy: 'no-cache',
        });
        this.customerIds = data.customers.items.map((customer) => customer.id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async fetchHeatingSystems(): Promise<void> {
      try {
        const { data } = await this.$apollo.query<HeatingSystemCollectionWithPaginationQuery>({
          query: HeatingSystemListQuery,
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          variables: {
            skip: 0,
            take: 50,
          },
          fetchPolicy: 'no-cache',
        });
        this.heatingSystems = data;
        this.heatingSystems.heatingSystemsList.items = data.heatingSystemsList.items.filter(
          (item) => item.heatingSystemMeasurementGroups.length > 0,
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    isComponentMetadataCompleted(component: HeatingSystemMetadataResponse): boolean {
      const metadata = component.metadata;
      switch (component.componentType) {
        case 'BOILER':
          if (!metadata.condensing) {
            return false;
          }
          return true;
        case 'WATER_HEATING':
        case 'PORTABLE_WATER_HEATING':
          if (
            !metadata.circulation_target_temp ||
            !metadata.circulation_temperature_tolerance ||
            !metadata.potable_water_target_temp ||
            !metadata.potable_water_temperature_tolerance ||
            !metadata.type_of_pwh
          ) {
            return false;
          }
          return true;
        default:
          return true;
      }
    },
  },
});
