import { formatMetricName } from '@/features/core/util/metric-formatters';
import { Metrics } from '@/features/core/util/metrics';
import { Column } from '@/features/ui/table/model';
import { keyBy } from 'lodash';
import { TreeNodeType } from '../tree-node-type/constants';
import { DomainUiSpotTableSpotFragment } from './__generated__/DomainUiSpotTableSpotFragment';

export type SpotTableSpot = DomainUiSpotTableSpotFragment;

export interface SpotTableRow {
  spot: SpotTableSpot;
  metrics: Metrics;
  attributeMap: Record<string, SpotTableSpot['attributes'][number]>;
}

export const LEADING_BASE_COLUMNS: readonly Column[] = Object.freeze([
  { name: 'path', label: 'Standort', width: 150 },
  { name: 'name', sortable: true, width: 200 },
]);

export const TRAILING_BASE_COLUMNS: readonly Column[] = Object.freeze([
  { name: 'health', width: 120 },
  { name: 'primaryMetric', label: 'Hauptwert', align: 'right', width: 100 },
]);

export type SpotTableDataLike = SpotTableData | SpotTableSpot[] | undefined;

export class SpotTableData {
  public static from(arg: SpotTableDataLike): SpotTableData {
    return new SpotTableData(arg instanceof SpotTableData ? arg.spots : arg ?? []);
  }

  public readonly rows: SpotTableRow[];
  public readonly metricColumns: Column[];
  public readonly metricColumnNames: string[];
  public readonly attributeColumns: Column[];
  public readonly attributeColumnNames: string[];
  public readonly columns: Column[];

  public constructor(public readonly spots: SpotTableSpot[]) {
    this.rows = this.spots.map((spot) => ({
      spot,
      metrics: Metrics.create(spot.latest.map(({ latest }) => latest)),
      attributeMap: keyBy(spot.attributes, 'configuration.name'),
    }));

    const metrics = [...new Set(this.spots.flatMap(({ latest }) => latest).map(({ latest }) => latest.name))];
    this.metricColumns = metrics
      .map((name) => ({
        name: `metric_${name}`,
        accessor: `metrics.all.${name}`,
        label: formatMetricName(name),
        hideable: true,
        sortable: `metrics.all.${name}`,
        align: 'right' as const,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    this.metricColumnNames = this.metricColumns.map(({ name }) => name);

    const attributes = [
      ...new Map(
        this.spots
          .flatMap(({ attributes }) => attributes)
          .map(({ configuration }) => [configuration.name, configuration]),
      ).values(),
    ].filter(({ treeNodeTypes }) => treeNodeTypes.includes(TreeNodeType.Spot));
    this.attributeColumns = attributes
      .map(({ name, __typename }) => ({
        name: `attribute_${btoa(name)}`,
        label: `Attribut: ${name}`,
        accessor: `attributeMap['${name}']`,
        hideable: true,
        sortable: `attributeMap['${name}'].value`,
        align: __typename === 'CheckboxAttributeConfiguration' ? ('right' as const) : ('left' as const),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    this.attributeColumnNames = this.attributeColumns.map(({ name }) => name);

    this.columns = [...LEADING_BASE_COLUMNS, ...this.attributeColumns, ...TRAILING_BASE_COLUMNS, ...this.metricColumns];
  }
}
